<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <welcome/>
    <identicon-image/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Welcome from '../components/Welcome.vue'
import IdenticonImage from '../components/IdenticonImage.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    Welcome,
    IdenticonImage
  }
}
</script>
