<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    
    <!-- <h1>Welcome to jueewo business cards</h1> -->

    <!-- <div id="animatedlogo">
            <img style="display:block;margin:auto;" src="../assets/jueewo_animation_n25_4b.gif" alt="jueewo animated logo"/>
    </div> -->


    <!-- <div style="text-align: center;">
      <div style="display: inline-block; text-align: left;">
          
        <b>>> Access code required </b>
        <br/>
        <b>>> scan QR code on our business card </b> or 
        <br/>
        <b>>> connect your wallet </b>
      </div>
    </div> -->

    <article style="margin: 2em; padding:1em; font-size: 1.4em; border-left-style: solid; border-color: red;">
      <div style="display: inline-block; text-align: left;">
        <b>> Access code required</b> 
        <br/>
        <b>> scan QR code on our business card </b>  or 
        <br/>
        <b>> connect your wallet </b>
        
      </div>
    </article>
    <!-- <br><br>
    >> For more info visit <a href="https://jueewo.com">jueewo.com</a>  -->

    <!-- <div id="animatedlogo">
            by <img style="width:7em; display:block; margin:auto;" src="../assets/jueewo_animation_n25_4b.gif" alt="jueewo animated logo"/>
    </div> -->

  </div>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
